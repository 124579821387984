import React from 'react'
import Nav from '../../components/Nav/Nav'
import './header.scss'

const Header = () => {
  return(
    <header className="header">            
      <h1 className="header__title">
        KIIGA 89 MF
      </h1>      
      <Nav />
    </header>
  ) 
}

export default Header