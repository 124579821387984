import React from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import './popup.scss'

const Popup = (props) => {

  return(
    <div className='modal'>
      <div className="modal__container">
        <Typography component="h6"  variant="h6" color="textPrimary">
          {/* "initial","inherit","primary","secondary","textPrimary","textSecondary","error */}
          {props.title}
        </Typography>
        <br/>
        <Typography color="textSecondary">
          {props.description}
        </Typography>
        <Button
          color="inherit"
          onClick={props.onClickOk}
          variant="outlined"
          className="btn"        
        >
          {props.buttonText}
        </Button>
      </div>
    </div>
  )
}

export default Popup
