import {
  ADD_POST_CACHE,
  REMOVE_POST_CACHE,
  RESET_UNREAD_MESSAGES,
  SET_LAST_LOADED_POST_ID,
  SET_POSTS_CACHE,
  SET_SCROLL_POSITION,
  SET_UNREAD_MESSAGE,
  UPDATE_POST_CACHE
} from '../actions/types'

const initialState = {
  cachedPosts: [],
  lastLoadedPostId: '',
  scrollPosition: 0,
  unreadMessages: 0
}

export default function postsReducer (state = initialState, action) {
  switch (action.type) {

    case SET_POSTS_CACHE: return { ...state, cachedPosts: [ ...action.payload, ...state.cachedPosts ]}

    case ADD_POST_CACHE: return {
        ...state,
        cachedPosts: [ ...state.cachedPosts, action.payload ]
      }

    case UPDATE_POST_CACHE: return {
        ...state,
        cachedPosts: state.cachedPosts.length > 0 && state.cachedPosts.map(post => {
            return post.id === action.payload.id ? action.payload : post
          })
      }

    case REMOVE_POST_CACHE: return {
        ...state,
        cachedPosts: state.cachedPosts.length > 0 &&
        state.cachedPosts.filter(post => post.id !== action.payload.id)
      }

    case SET_UNREAD_MESSAGE: return { ...state, unreadMessages: state.unreadMessages + 1 }

    case RESET_UNREAD_MESSAGES: return { ...state, unreadMessages: 0 }

    case SET_SCROLL_POSITION: return { ...state, scrollPosition: action.payload }

    case SET_LAST_LOADED_POST_ID: return { ...state, lastLoadedPostId: action.payload }

    default: return state
  }
}
