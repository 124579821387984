import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import heic2any from 'heic2any'
import TextField  from '@material-ui/core/TextField'
import InputAdornment   from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import { throttle } from '../../helpers/throttle'
import { setPostsCache } from '../../actions/setPostsCache'
import { setScrollPosition } from '../../actions/setScrollPosition'
import { resetUnreadMessages } from '../../actions/resetUnreadMessages'
import { setLastLoadedPostId } from '../../actions/setLastLoadedPostId'
import {
  addPost, deletePost, fetchMorePosts, fetchPosts, updatePost, uploadMediaFile
} from '../../chatApi/chatApi'
import FileUploadModal from '../../components/FileUploadModal/FileUploadModal'
import Menu from '../../components/Menu/Menu'
import Loader from '../../components/Loader/Loader'
import Page from '../../components/Page/Page'
import Modal from '../../components/Modal/Modal'
import Post from '../../components/Post/Post'
// import useOutsideClick from '../../helpers/useOutsideClick'
import './mainPage.scss'
import ArrowDownIcon from '../../icons/arrowDown'
import AttachIcon from '../../icons/attachIcon'

const offsetContextMenu = 10 // for onMouseOut
const limit = 10
const imageSizeLimit = 3 * 1000 * 1000 // 3 Mb
const videoSizeLimit = 40 * 1000 * 1000 // 40 Mb

const MainPage = (props) => {

  const { dispatch, posts } = props
  const { avatarPath, id: userId, name, lastName } = props.auth.userData

  const сontextMenuRef = useRef(null)
  const inputRef = useRef(null)
  const fileUploadRef = useRef(null)
  const postsRef = useRef(null)

  // const {
  //   elementRef: сontextMenuRef, isActive: isShowContextMenu, setIsActive: setIsShowContextMenu
  // } = useOutsideClick(false) 

  const [ isDisabled, setIsDisabled ] = useState(true)
  const [ isEditMode, setIsEditMode ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState('')
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isOpenErrorModal, setOpenErrorModal ] = useState(false)
  const [ isDisabledScrolling, disableScrolling ] = useState(false)
  const [ isShowContextMenu, setIsShowContextMenu ] = useState(false)
  const [ isShowDeleteModal, setIsShowDeleteModal ] = useState(false)
  const [ isShowScrollDownBtn, setShowScrollDownBtn ] = useState(false)
  const [ isShowFileUploadModal, setShowFileUploadModal ] = useState(false)
  const [ menuCoordinates, setMenuCoordinates ] = useState({})
  const [ message, setMessage ] = useState('')
  const [ selectedPost, setSelectedPost ] = useState({})
  const [ postMedia, setPostMedia ] = useState({})
  const [ innerHeight, setInnerHeight ] = useState(window.innerHeight)

  const onResize = () => {
    setInnerHeight(window.innerHeight)
  }

  window.onresize = onResize

   /* Loading first posts */
  useEffect(() => {
    if (posts.cachedPosts && posts.cachedPosts.length > 0) {
      if (posts.scrollPosition) {
        postsRef.current.scrollTo({ top: posts.scrollPosition })
      }

      return
    }
    setIsLoading(true)
    fetchPosts(
      limit,
      initialPosts => {
        dispatch(setPostsCache(initialPosts))
        dispatch(setLastLoadedPostId(initialPosts[0].id))
        setIsLoading(false)
        scrollDown()
      }
    )
  }, [ dispatch ])

  useEffect(() => {
    if (posts.unreadMessages > 0) {
      scrollDown({ behavior: 'smooth'} )
      dispatch(resetUnreadMessages())
    }
  }, [ dispatch, posts.unreadMessages ])

  /* To position the context menu correctly: */
  useEffect(() => {
    if (сontextMenuRef.current) {
      const { offsetWidth, offsetHeight } = сontextMenuRef.current
      const { clientWidth, clientHeight } = document.documentElement

      сontextMenuRef.current.style.left = (menuCoordinates.x + offsetWidth) < clientWidth
        ? menuCoordinates.x - offsetContextMenu + 'px'
        : menuCoordinates.x - offsetWidth + offsetContextMenu + 'px'

      сontextMenuRef.current.style.top = (menuCoordinates.y + offsetHeight) < clientHeight
        ? menuCoordinates.y - offsetContextMenu + 'px'
        : menuCoordinates.y - offsetHeight + offsetContextMenu + 'px'
    }
  }, [menuCoordinates])

  const scrollDown = (effect) => {
    if (postsRef.current) {
      if (effect) {
        postsRef.current.scrollTo({ ...effect, top: postsRef.current.scrollHeight })
      } else {
        postsRef.current.scrollTo({ top: postsRef.current.scrollHeight })
      }
    }
  }

  const handleScroll = () => {
    if (isDisabledScrolling || !postsRef.current) {
      disableScrolling(false)
      return
    }

    const { scrollTop, scrollHeight, clientHeight } = postsRef.current
    const scrollBottom = scrollHeight - scrollTop - clientHeight

    if (scrollTop <= 0.2 * scrollHeight) {
      loadMorePosts()
    }

    if (scrollBottom > 400) {
      setShowScrollDownBtn(true)
    } else {
      setShowScrollDownBtn(false)
    }

    dispatch(setScrollPosition(scrollTop))
  }

  const loadMorePosts = async () => {
    if (posts.lastLoadedPostId && !isDisabledScrolling) {
      const newPosts = await fetchMorePosts(posts.lastLoadedPostId)
      console.log('morePosts', newPosts)
      if (newPosts.length > 0) {
        dispatch(setPostsCache(newPosts))
        dispatch(setLastLoadedPostId(newPosts[0].id))
      } else {
        dispatch(setLastLoadedPostId(null))
      }
    }
  }

  const closeContextMenu = () => {
    setIsShowContextMenu(false)
  }
 
  const closeEditMode = () => {
    setMessage('')
    setIsEditMode(false)
    setSelectedPost({})
    setIsDisabled(true)
  }

  const closeModal = () => {
    setSelectedPost({})
    setIsShowDeleteModal(false)
  }

  const closeUploadModal = () => {
    setPostMedia({})
    setMessage('')
    setIsDisabled(true)
    setShowFileUploadModal(false)
  }

  const createPost = async (downloadUrl) => {
    const publicationDate = moment.utc().format() // TODO: refactor to ms or firebase time
    let post = {
      isDelivery: false,
      mediaUrl: downloadUrl ? downloadUrl : '',
      message,
      publicationDate,
      senderAvatarPath: avatarPath,
      senderId: userId,      
      senderLastName: lastName,
      senderName: name
    }
    disableScrolling(true)
    const response = await addPost(post)
    if (response && response.key) {
      scrollDown()
      await updatePost(response.key, '/isDelivery', true)
      closeUploadModal()
      disableScrolling(false)
    } else {
      console.log('Database is not available')
    }
  }

  const removePost = async () => {
    if (selectedPost.id) {
      await deletePost(selectedPost)
      closeContextMenu()
      setSelectedPost({})
      setIsShowDeleteModal(false)
    }
  }
  
  const onBlur = () => {
    if (isEditMode && !message) {
      setIsEditMode(false)
    }
  }
  
  const onInput = (event) => {
    setMessage(event.target.value)
    setIsDisabled(!event.target.value)
  }

  const openContextMenu = (event) => {
    event.preventDefault()
    const postId = event.currentTarget.dataset.postId
    getCurrentPost(postId)
    setIsShowContextMenu(true)
    setMenuCoordinates({ x: event.pageX, y: event.pageY })
  }

  const openDeleteModal = () => {
    setIsShowDeleteModal(true)
  }

  const reload = () => {
    window.location.reload()
  }

  const runMessageAction = (event, downloadUrl) => {
    if (message && isEditMode) {
      updateMessage()
    } else {
      (message || postMedia.fileName || downloadUrl) && createPost(downloadUrl)
    }
  }

  const updateMessage = async () => {
    if (selectedPost.id) {
      await updatePost(selectedPost.id, '/message', message)
      closeEditMode()
    }
  }
  
  const getCurrentPost = (postId) => {
    if (postId) {
      const selectedPost = posts.cachedPosts.filter(post => post.id === postId)
      setSelectedPost(selectedPost[0])
    }
  }

  const setMessageForEdit = () => {
    setIsEditMode(true)
    setMessage(selectedPost.message)
    inputRef.current.focus()
    setIsShowContextMenu(false)
  }

  const handleUploadFile = (event) => {
    event.preventDefault()

    if (fileUploadRef.current) {
      fileUploadRef.current.addEventListener('click', () => {
        fileUploadRef.current.value = ''
      })
    }

    if (event.target.files && event.target.files.length) {
      const src = URL.createObjectURL(event.target.files[0])//  URL.createObjectURL может вызвать утечку памяти, если не освобождать ресурсы после использования, когда компонент размонтируется или изображение изменяется 
      const uploadFile = event.target.files[0]
      const fileNameParts = uploadFile.name.split('.')
      const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase()

      if (
        (uploadFile.type.includes('image') && uploadFile.size >= imageSizeLimit) ||
        (fileExtension === 'heic' && uploadFile.size >= imageSizeLimit) ||
        (uploadFile.type.includes('video') && uploadFile.size >= videoSizeLimit)
      ) {
          showErrorModal('Слишком большой размер файла')
          return
        }

      if (fileExtension === 'heic') {
        convertHeicToWebp(uploadFile.name, uploadFile)
      } else if (uploadFile.type.includes('image')) {
        convertFileToWebp(uploadFile.name, src)
      } else if (uploadFile.type.includes('video')) {
        showErrorModal('Загрузка видео пока не поддерживается')
      } else {
        showErrorModal('Этот тип файла пока не поддерживается')
      }
    }
  }

  const convertHeicToWebp = (fileName, file) => {
    const reader = new FileReader()
    reader.onload = function (e) {
      const heicData = new Uint8Array(e.target.result)
      heic2any({ blob: new Blob([heicData], { type: 'image/heic' }), toType: "image/webp", quality: 0.7 })
      .then(function (blob) {
        convertHeicToBase64(fileName, blob)
      })
      .catch(function (error) {
        console.error('Convert .heic to Base64 error', error)
      })
    }
    reader.readAsArrayBuffer(file)
  }

  const convertHeicToBase64 = (fileName, blob) => {
    let reader = new FileReader()
    reader.readAsDataURL(blob) // convert Blob to base64
    reader.onload = function() {
      convertFileToWebp(fileName, reader.result)
    }
  }

  const convertFileToWebp = (uploadFileName, src) => {
    // Convert User Image to Canvas
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const userImage = new Image()
    userImage.src = src
    userImage.onload = () => {
      canvas.width = userImage.width
      canvas.height = userImage.height
      ctx.drawImage(userImage, 0, 0)

      // Convert Canvas to Webp
      const webpImage = canvas.toDataURL('image/webp') // or ('image/webp', 1) for hi quality

      // display converted User Image
      setPostMedia({ fileName: uploadFileName.split('.')[0] + '.webp', image: webpImage })
      setShowFileUploadModal(true)
    }
  }

  const uploadMedia = () => {
    setIsLoading(true)
    uploadMediaFile(postMedia, (downloadUrl) => {
      downloadUrl && runMessageAction(undefined, downloadUrl)
      setIsLoading(false)
    })
  }

  const createContextMenu = () => {
    return [
      { name: 'Комментировать', action: undefined },
      { name: 'Копировать', action: undefined },
      (selectedPost && selectedPost.senderId === userId && { name: 'Редактировать', action: setMessageForEdit }),
      (selectedPost && selectedPost.senderId === userId && { name: 'Удалить', action: openDeleteModal })
    ]
  }

  const showErrorModal = (errorText) => {
    setErrorMessage(errorText)
    setOpenErrorModal(true)
  }

  const closeErrorModal = () => {
    setErrorMessage('')
    setOpenErrorModal(false)
  }

  if(props.auth.error) {
    return(
      <Modal
        title="Connection error"
        description={props.auth.error}
        cancelButtonText="Повторить"
        okButtonText="Выйти из системы и повторно авторизоваться"
        onClickCancel={reload}
      />
    )
  }

  return (
    <Page>
      <div style={{height: innerHeight}} className="main-page">
        {
          isLoading
          ? <Loader color="inherit" />
          : <div ref={postsRef} onScroll={throttle(handleScroll, 400)} className='main-page__posts-container'>
              <ul className='main-page__post-list'>
                {
                  posts.cachedPosts.length > 0 &&
                  posts.cachedPosts.map(post => {
                    return (
                      <li key={post.id}>
                        <Post
                          post={post}
                          openContextMenu={openContextMenu}
                          scrollDown={scrollDown}
                        />
                      </li>
                    )
                  })
                }
              </ul>
              {
                isShowContextMenu &&
                <div ref={сontextMenuRef} className="main-page__context-menu">
                  <Menu close={closeContextMenu} menuItems={createContextMenu()} />
                </div>
              }
            </div>
        }
        <div className="main-page__input-wrap">
          <div className="main-page__input">
            {
              isEditMode &&
              <div className="main-page__edit-mode">
                <span className="main-page__edit-title">Редактирование</span>
                <span className="main-page__edit-close" onClick={closeEditMode}>Отменить</span>
              </div>
            }
            <TextField
              inputRef={inputRef}
              multiline
              rowsMax="15"
              value={message}
              placeholder="Сообщение..."
              variant="outlined"
              fullWidth
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="send"
                      color="primary"
                      onClick={runMessageAction}
                      disabled={isDisabled}
                      className="main-page__send-button"
                    >
                      <ArrowDownIcon width="42px" height="42px" />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              onChange={onInput}
              onBlur={onBlur}
            />
          </div>
          <div className='main-page__btn-container'>
            {
              isShowScrollDownBtn &&
              <div onClick={() => scrollDown({behavior: 'smooth'})} className="main-page__scroll-down">
                <ArrowDownIcon width="34" height="34" />
              </div>
            }
            <label className="main-page__attach-button">
              <input
                ref={fileUploadRef}
                type="file"
                className="main-page__input-upload"
                accept="video/*, image/*, .heic, .pdf"
                onChange={handleUploadFile}
              />
              <AttachIcon width="24" height="24" />
            </label>
          </div>
        </div>
        {
          isShowDeleteModal &&
          <Modal
            title="Удаление сообщения"
            description="Удалить это сообщение?"
            okButtonText="Удалить"
            cancelButtonText="Отменить"
            onClickOk={removePost}
            onClickCancel={closeModal}
          />
        }
        {
          (isOpenErrorModal && errorMessage) &&
          <Modal
            title="Sorry"
            description={errorMessage}
            cancelButtonText="Закрыть"
            onClickCancel={closeErrorModal}
          />
        }
        {
          isShowFileUploadModal &&
          <FileUploadModal
            title="Создание публикации"
            okButtonText="Отправить"
            cancelButtonText="Отменить"
            onClickOk={uploadMedia}
            onClickCancel={closeUploadModal}
          >
            <TextField
              multiline
              rowsMax="99"
              value={message}
              placeholder="Message"
              fullWidth
              size="small"
              className="upload-modal__message"
              onChange={onInput}
              onBlur={onBlur}
            />
            {
              isLoading
              ? <Loader />
              : <img
                  src={postMedia.image ? postMedia.image : null}
                  alt="preview"
                />
            }
          </FileUploadModal>
        }
      </div>
    </Page>
  )
}

const mapStoreToProps = (store) => {
  return {
    auth: store.auth,
    posts: store.posts
  }
}

export default connect(mapStoreToProps)(MainPage)
