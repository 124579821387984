import React, { useEffect, useState } from 'react'
import Recaptcha from 'react-recaptcha'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Loader from '../../components/Loader/Loader'
import Modal from '../../components/Modal/Modal'
import Button from '@material-ui/core/Button'
import { sendRequestPasswordData } from '../../api'
import '../LoginModal/loginModal.scss'
import './captcha.scss'

const telephoneErrorText = 'После "+" введите цифры, начиная с кода страны'
const emailErrorText = 'Введите корректный e-mail'
const SuccessMessage = 'Если ты действительно из рядов КИИГА 83-89 МФ, в ближайшее время тебе на почту будут отправлены пароль и персональный токен для входа на ресурс kiiga89mf.com'

const useStyles = makeStyles(() => {
  return {
    helperText: {
      color: 'red',
      fontSize: 14
    }
  }
})
 
const PasswordRequestModal = (props) => {
  const classes = useStyles()

  const [ telephoneError, setTelephoneError ] = useState('')
  const [ emailError, setEmailError ] = useState('')
  const [ userData, setUserData ] = useState({
    lastName: '',
    firstName:'',
    telephone: '',
    email: ''
  })
  const [ isDisabled, setIsDisabled ] = useState(true)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ сaptchaError, setCaptchaError ] = useState('')
  const [ isCaptchaVerified, setCaptchaVerified ] = useState(false)
  const [ isCaptchaVisable, setCaptchaVisable ] = useState(false)
  const [ isOpenResultModal, setOpenResultModal ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState('')
  
  useEffect(() => {
    if (userData.lastName && userData.firstName && userData.telephone.length > 1 && userData.email && !emailError) {
      setCaptchaVisable(true)
      setIsDisabled(false)
    } else setIsDisabled(true)
  }, [ userData, emailError ])

  const verifyCaptchaCallback = (response) => {
    if(response) {
      setCaptchaVerified(true)
      setCaptchaError('')
    }
  }

  const captchaValidate = () => {
    if(isCaptchaVerified) {
      sendData()
    } else {
      setCaptchaError('Подтверди, что ты не робот')
    }
  }
  
  const sendData = async () => {
    setIsLoading(true)
    const response = await sendRequestPasswordData(userData)
    setOpenResultModal(true)
    if (response) {
      if (response.data.error || !response.isSuccess) {
        setErrorMessage('Не удалось отправить email с паролем' + response.data.error)
      }
    } else {
        setErrorMessage('Cервер не отвечает, попробуйте повторить позже')
        console.log('Server error')
      }
    setIsLoading(false)
    setCaptchaVerified(false)
  }

  const closeResultModal = () => {
    if (errorMessage) {
      setErrorMessage('')
      setOpenResultModal(false)
    } else {
      setOpenResultModal(false)
      props.closeRequestPasswordModal()
    }
  }

  const onInput = (event) => {
    let value = event.target.value
    if (event.target.name === 'lastName' || event.target.name === 'firstName') {
      value = value.replace(/[^а-яёa-z ]/ig, '') // only letters
    }
    if (event.target.name === 'telephone') {
      value = value.replace(/\D/g, '') // only numbers
      value = value[0] === '+' ? value : '+' + value
      setTelephoneError(value.length > 1 ? '' : telephoneErrorText)
    }
    if (event.target.name === 'email') {
      let reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
      setEmailError(reg.test(value) ? '' : emailErrorText)
    }
    setUserData({ ...userData, [ event.target.name ]: value })
  }

  if (isOpenResultModal) {
    return (
      <Modal
        title={errorMessage ? 'Ошибка' : 'Данные отправлены'}
        description={errorMessage ? errorMessage : SuccessMessage}
        okButtonText="Ok"
        onClickOk={closeResultModal}
      />
    )
  }

  return (
    <Modal
      title={isLoading ? 'Отправка данных' : 'Данные для получения пароля'}
      okButtonText="Отменить"
      onClickOk={props.closeRequestPasswordModal}
    >
      {
        isLoading ? <div className="login-modal-loader"><Loader /></div> :
        <div>
          <TextField
            onChange={onInput}
            name="lastName"
            label="Фамилия*"
            fullWidth
            value={userData.lastName}
          />
          <TextField
            onChange={onInput}
            name="firstName"
            label="Имя*"
            fullWidth
            value={userData.firstName}
          />
          <TextField
            onChange={onInput}
            name="telephone"
            label="Телефон*"
            fullWidth
            helperText={telephoneError}
            FormHelperTextProps={{
              classes:{
                root: classes.helperText
              }
            }}
            value={userData.telephone}
          />
          <TextField
            onChange={onInput}
            name="email"
            label="Email*"
            type="email"
            fullWidth
            helperText={emailError}
            FormHelperTextProps={{
              classes:{
                root: classes.helperText
              }
            }}
            value={userData.email}
          />
          <p className="autorize-registr">* - обязательные поля</p>
          {
            isCaptchaVisable &&
            <div className="recaptcha-wrapper">
              <Recaptcha
                sitekey="6LeK988ZAAAAAOKzC_hPA32TB9oZ-fLJ-N5GBz--"
                render="explicit"
                // onloadCallback={recaptchaLoaded}
                verifyCallback={verifyCaptchaCallback}
                hl="ru"
              />
              <p className="capthcaError">
                { сaptchaError ? сaptchaError : null }
              </p>
            </div>
          }
          <div className="submit">
            <Button
              onClick={captchaValidate}
              variant="outlined"
              color="inherit"
              disabled={isDisabled}
            >
              Запросить пароль
            </Button>
          </div>
          <p className="autorize-registr">
            Есть пароль для входа?&nbsp;
            <span onClick={props.openLoginModal} className="autorize-registr-link">Вход здесь</span>.
          </p>
        </div>
      }
    </Modal>
  )
}

export default PasswordRequestModal
