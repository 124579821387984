import React from 'react'
// import Header from '../../components/Header/Header'
import Page from '../../components/Page/Page'
import './testPage.scss'

const TestPage = (props) => {
  return (
    <Page>
      {/* <Header /> */}
      <div class="main-content">MAIN CONTENT</div>
      <footer class="main-footer">FOOTER</footer>
      {/* <main class="main-container"> */}
        {/* <header class="main-header">HEADER</header> */}
        {/* <div class="main-content">MAIN CONTENT</div> */}
        {/* <footer class="main-footer">FOOTER</footer> */}
      {/* </main> */}
    </Page>
  )
}

export default TestPage