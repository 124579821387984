import React from 'react'

const ArrowDownIcon = (props) => {

  return(
    <svg width={props.width} height={props.height} viewBox="-6 -6 36 36" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="1.9" strokeLinecap="round" strokeLinejoin="round">
      <g id="SVGRepo_iconCarrier">
        <line x1="12" y1="5" x2="12" y2="19"/>
        <polyline points="19 12 12 19 5 12"/>
      </g>
    </svg>
  )
}

export default ArrowDownIcon
