import { initializeApp } from 'firebase/app'
import { getStorage, ref, list } from 'firebase/storage'

export const firebaseConfig = {
  apiKey: 'AIzaSyCjZMmI7czwBmXfJHr3N7-cjvydkytfP2c',
  authDomain: 'mf89-9d14e.firebaseapp.com',
  projectId: 'mf89-9d14e',
  storageBucket: 'mf89-9d14e.appspot.com',
  messagingSenderId: '729132297205',
  appId: '1:729132297205:web:c8ad8920f464bfd13e0ebc',
  databaseURL: 'https://mf89-9d14e-default-rtdb.europe-west1.firebasedatabase.app/'
}

// Initializing Firebase
const firebase = initializeApp(firebaseConfig)

export const storage = getStorage(firebase)

// Create a reference under which you want to list
export const listRef = ref(storage, 'video') //  or ref(storage, 'files/uid')

export const getList = list
