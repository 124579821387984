import React, { Component } from 'react'
import { connect } from 'react-redux'
import Avatar from 'react-avatar-edit'
import uploadAvatar from '../../actions/uploadAvatar'
import Button from '@material-ui/core/Button'
import Loader from '../../components/Loader/Loader'
import './uploader.scss'

class Uploader extends Component {

  constructor(props) {
    super(props)
    this.state = {
      preview: null,
      btnVisable: false,
      isLoading: false
    }
  
    this.onCrop = this.onCrop.bind(this)
    this.onClose = this.onClose.bind(this)
    this.loadPhoto = this.loadPhoto.bind(this)
  }

  onClose() {
    this.setState({ ...this.state, preview: null, btnVisable: false })
  }
  
  onCrop(preview) {
    this.setState({ ...this.state, preview, btnVisable: true })
  }

  async loadPhoto(e)  {

    e.preventDefault()
    const data = {
      userPhoto: this.state.preview,
      userId: this.props.auth.userData.id
    }

    this.setState({ ...this.state, isLoading: true })
    await uploadAvatar(data)
    this.setState({ ...this.state, isLoading: false })
    this.onClose()
  }

  render () {
    return (
      <div className="avatar">
        <Avatar
          width={200}
          height={200}
          label={'Выбрать фото'}
          closeIconColor="red"
          onCrop={this.onCrop}
          onClose={this.onClose}
          // exportQuality // from 0 to 1, number
          // exportMimeType //  image/jpeg, image/png (Default: image/png)
          // src={this.state.src}
        />
        {
          this.state.isLoading && <Loader color="inherit" />
        }
        {
          this.state.preview && !this.state.isLoading
          ? <img
              src={this.state.preview}
              className="avatar-preview"
              alt="Предосмотр"
            />
          : null
        }
        {
          this.state.btnVisable && !this.state.isLoading
          ? <div className="avatar-btn">
              <Button
                type="submit"
                onClick={this.loadPhoto}
                variant="outlined"
                color="primary"
              >
                Загрузить
              </Button>
            </div>
          : null
        }
      </div>
    )
  }
}
const mapStoreToProps = (store) => {
  return {
    auth: store.auth
  }
}

export default connect(mapStoreToProps)(Uploader)
