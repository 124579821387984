import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './store'
import App from './App'
import './reset.css'
import './index.scss'

ReactDOM.render((
  <Provider store={store}>
    <App />
  </Provider>  
), document.getElementById('root'))