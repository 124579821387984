import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom"
import { connect } from 'react-redux'
import { sendRegistrData } from '../../api'
import Loader from '../../components/Loader/Loader'
import Popup from '../../components/Popup/Popup'
import { setLogin } from '../../actions/setLogin'
import '../LoginModal/LoginModal'

 
const Registration = (props) => {
  const [isLoader, setIsLoader] = useState(false)  
  const [isShowPopup, setIsShowPopup] = useState(false)

  const [isSuccess, setIsSuccess] = useState(undefined)
  const [messageTitle, setMessageTitle] = useState('')
  const [messageDescription, setMessageDescription] = useState('')

  const [userData, setUserData] = useState({})
  const [userName, setUserName] = useState('')

  const [responseUserData, setResponseUserData] = useState({})

  useEffect(() => {
    let urlParams = (document.location.search).substring(1).split('&')
    const id = urlParams[0].split('=')[1]
    setUserName(decodeURIComponent(urlParams[1].split('=')[1]))
    const secret = urlParams[2].split('=')[1]
    setUserData({ id, secret })
  }, [])
  
  const sendData = async () => {
    setIsLoader(true)
    try {
      const response = await sendRegistrData(userData)
      if (response) {
        setMessageTitle(response.successSaveData && response.successSendEmail ? 'Success!' : 'Ошибка на сервере')
        setMessageDescription(response.successSaveData && response.successSendEmail ? 'Лови пароль на email. Не забудь его потом поменять на свой!' : response.error)
      } else {
        setMessageTitle('Ошибка на сервере')
        setMessageDescription('Ошибка запроса')
      }
      setIsSuccess(response.successSaveData && response.successSendEmail ? true : false)
      setResponseUserData(response.userData ? response.userData : {})
    } catch (error) {
      console.log('error', error)
      setMessageTitle('Ошибка на сервере')
      setMessageDescription(error)
    } finally {
      setIsLoader(false)
      setIsShowPopup(true)
    }
  }

  const onClickOk = () => {
    if (isSuccess) {
      props.dispatch(setLogin({ isLogined: true, isTokenVerified: true, userData: responseUserData }))
    }
    setIsShowPopup(false)
    props.history.push(isSuccess ? '/main' : '/')
  }

  return(
    <div className="auth-bg-img">
      <div className="auth-bg-color">
        { isLoader ? <Loader color='secondary' /> : null }
        {
          !isShowPopup && !isLoader
          ? <Popup onClickOk={sendData}
              title={`Привет, ${userName}!`}
              description="После клика на 'OK' тебе на email будет отправлен временный пароль для входа на kiiga89mf.com 
                          Настоятельно рекомендуется сразу изменить его на свой собственный. Сделать это можно в своем профиле"
              buttonText="Ok"
            />
          : isLoader
            ? <Loader color='secondary' />
            : <Popup onClickOk={onClickOk}
                title={messageTitle}
                description={messageDescription}
                buttonText="Ok"
              />
        }
      </div>
    </div>
 )
}

export default withRouter(connect()(Registration))
