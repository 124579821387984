import {
  SET_LOGIN,
  SET_LOGIN_WITH_CREDENTIALS,
  SET_LOGOUT,
  SET_USER_AVATAR
} from "../actions/types"

const initialState = {
  userData: {
    avatarPath: '',
    isAvatarUpdated: false,
    id: '',
    lastName: '',
    firstName: '',
    email: '',
    telephone: '',
    city: '',
    registrDate: ''
  },
  isTokenVerified: null,
  isLogined: false,
  error: '',
};

export default function authReducer (state = initialState, action) {
  switch (action.type) {

    case SET_LOGIN_WITH_CREDENTIALS:
      if (action.payload.isLogined) {
        return { ...state, ...action.payload }
      }

    break

    case SET_LOGIN:
      if (action.payload.isTokenVerified) {
        return { ...state, ...action.payload }
      } else {
          return {
            ...state,
            isShowPopup: true,
            error: action.payload.error || "Проблемы с получением данных с сервера"
          }
        }

    case SET_USER_AVATAR:
      return { ...state,
        userData: {
          ...state.userData,
          avatarPath: action.payload.avatarPath,
          isAvatarUpdated: action.payload.isUpdated
        }
      }

    case SET_LOGOUT:
      return { ...state, isLogined: action.payload }

    default: return state
  }
}
