import React from 'react'
import './modal.scss'

const Modal = (props) => {

  return(
    <div className='modal'>
      <div className="modal__content">
        <h4 className="modal__content-title">{props.title}</h4>
        {props.description && <p className="modal__content-description">{props.description}</p>}
        {props.children}
        <div className='modal__content-buttons'>
          { props.cancelButtonText &&
            <span
              onClick={props.onClickCancel}
              className="modal__button modal__button--cancel"
            >
              {props.cancelButtonText}
            </span>
          }
          { props.okButtonText &&
            <span
              onClick={props.onClickOk}
              className="modal__button modal__button--ok"
            >
              {props.okButtonText}
            </span>
          }
        </div>
      </div>
    </div>
  )
}

export default Modal
