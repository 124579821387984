export function throttle(func, wait) {
  let waiting = false
  return function () {
    if (waiting) {
      return
    }

    waiting = true
    setTimeout(() => {
      func.apply(this, arguments)
      waiting = false
    }, wait)
  }
}
