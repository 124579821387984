import React, { useEffect, useState } from "react"
import { NavLink, useLocation, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Badge from '@material-ui/core/Badge'
import { setLogOut } from '../../actions/setLogOut'
import './nav.scss'
import ArrowDownIcon from '../Down/Down'
import ChatIcon from '../../icons/chatIcon'
import IconUser from "../../images/icons/IconUser"
import IconVideo from "../../images/icons/IconVideo"

const Nav = ({ auth, posts, history, dispatch }) => {

  const userData = auth.userData

  const location = useLocation().pathname.split("/")[1]
  const invisible = location === 'main' || location === '' || posts.unreadMessages === 0
  const [ windowWidth, setWindowWidth ] = useState(window.innerWidth)

  let iconSize = 26
  if (windowWidth >= 768) iconSize = 30

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    let dropdownMenu = document.getElementById('dropdownMenu')
    let profile = document.querySelector('.nav-user')
    document.addEventListener('click', (e) => {
      if (!profile.contains(e.target) && !dropdownMenu.classList.contains('hidden'))  {
        toggleMenu()
      }
    })
  }, [])

  const toggleMenu = () => {
    let dropdownMenu = document.getElementById('dropdownMenu')
    let down = document.getElementById('down')
    dropdownMenu.classList.toggle('hidden')
    down.classList.toggle('active')
  }

  const openProfile = () => {  
    history.push('/profile')
  }

  const closeProfile = () => {
    history.goBack()
  }

  const logout = () => {
    dispatch(setLogOut())
    history.push('/')
  }

  return (    
    <div className="nav">
      <NavLink to="/main"
        activeClassName="nav-item-active"
        className={location === 'main' || location === '' ? 'nav-item nav-item-active' : 'nav-item'}
        title="Чат"
      >
        <Badge
          badgeContent={posts.unreadMessages}
          color="error"
          max={99}
          invisible={invisible}
        >
          <ChatIcon
            className={location === 'main' || location === '' ? 'nav-item-icon-active' : 'nav-item-icon'}
            size={iconSize}
          />
        </Badge>
      </NavLink>

      <NavLink to="/test"
        activeClassName="nav-item-active"
        className={location === 'test' ? 'nav-item nav-item-active' : 'nav-item'}
        title="test"
      >
        <IconVideo
          className={location === 'test' ? 'nav-item-icon-active' : 'nav-item-icon'}
          size={iconSize}
        />
      </NavLink>

      <NavLink to="/video"
        activeClassName="nav-item-active"
        className={location === 'video' ? 'nav-item nav-item-active' : 'nav-item'}
        title="Video"
      >
        <IconVideo
          className={location === 'video' ? 'nav-item-icon-active' : 'nav-item-icon'}
          size={iconSize}
        />
      </NavLink>

      <div onClick={toggleMenu} className="nav-user">
        {
          windowWidth <= 768
          ? <div className={location === 'profile' ? 'nav-item nav-item-active' : 'nav-item'} style={{paddingBottom: 0 }}>
              <IconUser
                className={location === 'profile' ? 'nav-item-icon-active' : 'nav-item-icon'}
                size={iconSize}
              />
            </div>
          : userData.avatarPath
            ? <img
                src={userData.avatarPath + '?' + Math.random(3)}
                width={50} height={50}
                title="User avatar"
                alt="User avatar"
              />
            : <IconUser
              className={location === 'profile' ? 'nav-item-icon-active' : 'nav-item-icon'}
              size={iconSize}
            />
        }

        <ArrowDownIcon className="down"/>

        <ul  id="dropdownMenu" className="dropdown-menu hidden">
          <li>
            <span>
              Авторизован как
            </span>
            <div>
              {userData.lastName}
            </div>
          </li>

          {window.location.pathname !== '/profile'
          ? <li  onClick={openProfile}>
              Профиль
            </li>
          : <li onClick={closeProfile}>
              Закрыть профиль
            </li>
          }

          <li onClick={logout}>
            Выйти из системы
          </li>
        </ul>
      </div>
    </div>
  )
}

const mapStoreToProps = (store) => {
  return {
    auth: store.auth,
    posts: store.posts
  }
}

export default connect(mapStoreToProps)(withRouter(Nav))
