import React, { useEffect, useState } from 'react'
import { Avatar } from '@material-ui/core'
import moment from 'moment'
import { getTimeAgo } from '../../helpers/formatDate'
import './post.scss'

const timeAgoLimit = 4 * 43200000 // 2 twenty-four hours
const timeAgoInterval = 30 * 1000 // 30 sec

const maxCharacters = 150

const Post = (props) => {

  const { senderAvatarPath, isDelivery, message, mediaUrl, id, publicationDate, senderName, senderLastName } = props.post
  const [ currentTime, setCurrentTime ] = useState(new Date().getTime())
  const [expanded, setExpanded] = useState(false)

  const localPublicationDate = publicationDate
    ? moment.parseZone(publicationDate).local().format('DD.MM.YYYY')
    : ''

  const formattedDate = (currentTime - moment(publicationDate).valueOf()) < timeAgoLimit
    ? getTimeAgo(publicationDate)
    : localPublicationDate

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date().getTime())
    }, timeAgoInterval)

    return () => clearInterval(interval)
  }, [currentTime])

  const onLoad = () => {
    props.scrollDown()
  }

  const renderMessage = () => {
    if (expanded) {
      return message
    } else {
      return message.length < maxCharacters
        ? message
        : message.slice(0, maxCharacters) + '....'
    }
  }

  const toggleMessage = () => {
    setExpanded(!expanded)
  }

  const seeMoreText = expanded ? ' (показ.меньше)' : ' Ещё'

  return (
    <div onContextMenu={props.openContextMenu} className="post" data-post-id={id}>
      <div className='post__author'>
        <Avatar src={senderAvatarPath + '?' + Math.random(3)} alt={`${senderName} ${senderLastName}`}/>
        <div className="post__author-info">
          <span className="post__author-info-name">{senderName} {senderLastName}</span>
          <span className="post__author-info-date">{formattedDate}</span>
        </div>
      </div>
      <div className="post__content">
        <p className='post__content-message'>
          <span>{renderMessage()}</span>
          {message.length > maxCharacters && <span className='post__content-see-more' onClick={toggleMessage}>{seeMoreText}</span>}
        </p>
        {
          mediaUrl &&
          <img src={mediaUrl} className="post__content-img" alt={mediaUrl && 'Photo'} onLoad={onLoad} />
        }
        {
          <span className={`post__check-delivery ${!isDelivery && 'post__check-delivery--error'}`}>
            &#10003; {!isDelivery && '!'}
          </span>
        }
      </div>
    </div>
  )
}

export default Post
