import { createStore, applyMiddleware } from 'redux'
import rootReducer from './reducers/rootReducer'
import { composeWithDevTools } from '@redux-devtools/extension'


const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware()
  )
)

export default store
