import { API } from '../api/api.js'
import { setUserAvatar } from './setUserAvatar'
import store from '../store'

export default async function uploadAvatar(userData) {
  try {
    const response = await API.post({ url: 'avatarUpload.php', data: userData })
    if (response.isSuccess) {
      store.dispatch(setUserAvatar(response.data))
    }
  } catch(error) {
      console.log('error file upload', error)
  }
}