import moment from 'moment'
import 'moment/locale/ru'

moment.locale('ru')

export const getTimeAgo = (publicationDate) => {
  if (publicationDate) {
    return moment(publicationDate).fromNow()
  }
  return
}
