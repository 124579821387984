import React from 'react'
import './menu.scss'

const Menu = (props) => {
  return (
    <ul onMouseLeave={props.close} className='menu'>
      {
        props.menuItems.map((item, index) => {
          const isRemoveItem = item.name ==='Удалить'
          return (
            item.name &&
            <li
              key={index}
              className={`menu__item ${isRemoveItem && 'menu__item--remove'}`}
              onClick={item.action}>{item.name}
            </li>
          )
        })
      }
    </ul>
  )
}

export default Menu